<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-row>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-text-field
          outlined
          v-model="address.supplier.kdNr"
          label="Kundennummer"
          append-icon="mdi-numeric"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-text-field
          outlined
          label="UID"
          v-model="address.supplier.uid"
          append-icon="mdi-identifier"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4" md="4" xl="4">
        <v-autocomplete
          outlined
          label="Lieferantengruppe"
          :items="this.supplierOptions.supplierGroups"
          item-text="description"
          item-value="id"
          v-model="address.supplier.supplierGroup"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4" xl="4">
        <v-autocomplete
          outlined
          label="Verband"
          :items="this.supplierOptions.associations"
          item-text="displayName"
          item-value="key"
          v-model="address.supplier.association"
          prepend-inner-icon="mdi-account-group"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-autocomplete
          outlined
          label="Branche"
          :items="this.supplierOptions.industrialSectors"
          item-text="description"
          item-value="id"
          v-model="address.supplier.industrialSector"
          prepend-inner-icon="mdi-account-hard-hat"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-autocomplete
          outlined
          label="Fibugruppe"
          :items="this.supplierOptions.fibuGroups"
          item-text="description"
          item-value="id"
          v-model="address.supplier.fibuGroup"
          prepend-inner-icon="mdi-account-group"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>

    <h2 class="overline font-weight-black mt-3">Lieferung und Bestellung</h2>
    <v-divider />

    <v-row class="mt-3">
      <v-col cols="12" lg="3" md="3" xl="3">
        <v-autocomplete
          outlined
          label="Versandart"
          :items="this.supplierOptions.shippingMethods"
          item-text="description"
          item-value="id"
          v-model="address.supplier.deliveryAndOrder.shippingMethod"
          prepend-inner-icon="mdi-truck"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" xl="3">
        <v-autocomplete
          outlined
          label="Lieferbedingung"
          :items="this.supplierOptions.shippingConditions"
          item-text="description"
          item-value="id"
          v-model="address.supplier.deliveryAndOrder.shippingCondition"
          prepend-inner-icon="mdi-truck-alert"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="PA auf Anfrage"
          v-model="address.supplier.deliveryAndOrder.priceOnDemand"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="PA auf Bestellung"
          v-model="address.supplier.deliveryAndOrder.priceOnOrder"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="Rechnungssperre"
          v-model="address.supplier.deliveryAndOrder.invoiceBlocking"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          suffix="Tage"
          outlined
          :rules="[rules.onlyPositive, rules.onlyNumbers]"
          v-model="
            address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval
          "
          label="Sammelliefers. Int."
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          suffix="Tage"
          outlined
          :rules="[rules.onlyPositive, rules.onlyNumbers]"
          v-model="address.supplier.deliveryAndOrder.collectiveInvoiceInterval"
          label="Sammelr. Int."
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="Filialdirektbel."
          v-model="address.supplier.deliveryAndOrder.directBranchShipping"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="Kundendirektbel."
          v-model="address.supplier.deliveryAndOrder.directCustomerDelivery"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="2" md="2" xl="2">
        <v-checkbox
          label="Verband Mwst."
          v-model="address.supplier.deliveryAndOrder.associationVat"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          outlined
          label="Kreditorennummer"
          append-icon="mdi-numeric"
          v-model="address.supplier.deliveryAndOrder.creditorNumber"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" xl="3">
        <v-autocomplete
          outlined
          label="Anbruchpackungen"
          :items="this.supplierOptions.openedPacks"
          prepend-inner-icon="mdi-package-variant"
          v-model="address.supplier.deliveryAndOrder.openedPacks"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="3" xl="3">
        <v-text-field
          outlined
          label="Dezimalstellen"
          :rules="[rules.onlyPositive, rules.onlyNumbers]"
          append-icon="mdi-decimal-comma"
          v-model="address.supplier.deliveryAndOrder.decimalPlaces"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="3" xl="3"
        ><v-text-field
          outlined
          label="Mindestbestellwert"
          :rules="[rules.onlyPositive, rules.onlyNumbers]"
          append-icon="mdi-currency-eur"
          v-model="address.supplier.deliveryAndOrder.minimumOrderValue"
        >
        </v-text-field
      ></v-col>
    </v-row>

    <h2 class="overline font-weight-black mt-3">Bezahlung</h2>
    <v-divider />

    <v-row class="mt-3">
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-cash"
          :items="this.supplierOptions.paymentConditions"
          item-text="description"
          item-value="id"
          label="Zahlungsbedingung"
          v-model="address.supplier.payment.paymentConditions"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-cash"
          :items="this.supplierOptions.paymentMethods"
          item-text="description"
          item-value="id"
          label="Zahlungsart"
          v-model="address.supplier.payment.paymentTypes"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-text-field
          outlined
          append-icon="mdi-credit-card"
          label="IBAN"
          v-model="address.supplier.payment.iban"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" xl="6">
        <v-text-field
          outlined
          append-icon="mdi-numeric"
          label="BIC"
          v-model="address.supplier.payment.bic"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-checkbox
          label="Sperre Zahlungsvorschlag"
          prepend-icon="mdi-lock"
          v-model="address.supplier.payment.lockPaymentProposal"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              label="Einzugsermächtigungsdatum"
              append-icon="mdi-calendar"
              readonly
              v-model="address.supplier.payment.directDepitAuthorisationDate"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker type="date" color="primary" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="4" xl="4" md="4">
        <v-autocomplete
          outlined
          label="Rabattart"
          :items="this.supplierOptions.discountTypes"
          item-text="description"
          item-value="id"
          v-model="address.supplier.payment.discountType"
          prepend-inner-icon="mdi-percent"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" lg="2" xl="2" md="2">
        <v-text-field
          outlined
          label="Rabattausmaß"
          suffix="%"
          :rules="[rules.onlyNumbers]"
          v-model="address.supplier.payment.discount"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      supplierOptions: state => state.crmAddresses.supplierOptions,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value =>
        value == '' ||
        value == null ||
        /^-?\d{1,}(?:,\d+)?$/.test(value) ||
        'Nur Zahlen sind erlaubt!'
    },

    date: null,
    menu: false,
    formValidity: true
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidSupplier', newValidity)
    }
  }
}
</script>

<style></style>
