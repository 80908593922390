<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            v-model="address.isCompany"
            :label="
              addressTypes.find(type => type.fldName === 'kd_kunde')
                .fldDisplayName
            "
            @change="loadCompanyDefaultOptions(), updateNonPersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_lieferant')
                .fldDisplayName
            "
            v-model="address.isSupplier"
            @change="loadSupplierDefaultOptions(), updateNonPersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_heim')
                .fldDisplayName
            "
            v-model="address.isResidence"
            @change="updateNonPersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_krankenkasse')
                .fldDisplayName
            "
            v-model="address.isHealthInsuranceCompany"
            @change="
              loadHealthInsuranceCompanyOptions(), updateNonPersonNavList()
            "
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_sachwalter')
                .fldDisplayName
            "
            v-model="address.isAdultRepresentation"
            @change="updateNonPersonNavList()"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="2" xl="2" lg="2">
          <v-text-field
            v-model="address.salutation"
            outlined
            label="* Anrede"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="10" lg="10" xl="10"
          ><v-text-field
            outlined
            label="* Name"
            append-icon="mdi-domain"
            :rules="[rules.required]"
            v-model="address.name"
            @change="checkIfDuplicate()"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="7" xl="7" lg="7">
          <v-autocomplete
            append-icon="mdi-earth"
            outlined
            label="* Land"
            :items="this.options.countries"
            item-text="description"
            item-value="id"
            ref="countryAutocomplete"
            :rules="[rules.required]"
            clearable
            v-model="address.address.country"
            @change="checkIfDuplicate(), autofillCity()"
            auto-select-first
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2" xl="2" lg="2">
          <v-text-field
            outlined
            label="* PLZ"
            v-model="address.address.zip"
            :rules="[rules.onlyPositive, rules.required]"
            @change="checkIfDuplicate(), autofillCity()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" xl="5" lg="5">
          <v-text-field
            outlined
            label="* Ort"
            v-model="address.address.city"
            :rules="[rules.required]"
            @change="checkIfDuplicate()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-autocomplete
            outlined
            label="* Straße"
            append-icon="mdi-home"
            :rules="[rules.required]"
            :items="streetSuggestions"
            v-model="street"
            @change="checkIfDuplicate()"
            @keyup="updateStreetSuggestions(), updateModel()"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            outlined
            label="* Hausnummer"
            :rules="[rules.required, rules.onlyPositive]"
            v-model="housenumber"
            @keyup="updateModel()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            label="* Tel. 1"
            append-icon="mdi-phone"
            v-model="address.tel"
            :rules="[rules.onlyPositive, rules.required]"
          ></v-text-field
          ><!-- TODO: telefonnummer zb: +43 676 3012323 möglich machen!!!-->
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            append-icon="mdi-pencil"
            label="Anmerkung"
            v-model="address.telNote"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            label="Tel. Mobil"
            append-icon="mdi-phone"
            v-model="address.mobile"
            :rules="[rules.onlyPositive]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            append-icon="mdi-pencil"
            label="Anmerkung"
            v-model="address.mobileNote"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            append-icon="mdi-email"
            label="E-Mail"
            v-model="address.email"
            placeholder="max.mustermann@gmail.com"
            :rules="[rules.emailFormat]"
            type="email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-autocomplete
            outlined
            label="Heim"
            clearable
            :items="this.options.residences"
            item-text="displayName"
            item-value="key"
            v-model="address.residence"
            auto-select-first
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-autocomplete
            outlined
            label="Erwachsenenvertretung"
            :items="this.options.adultRepresentations"
            item-text="displayName"
            item-value="key"
            v-show="!address.isAdultRepresentation"
            clearable
            v-model="address.adultRepresentation"
            auto-select-first
          ></v-autocomplete> </v-col
        ><v-spacer></v-spacer
      ></v-row>
    </v-container>
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
    <DuplicateFoundDialog ref="duplicate"></DuplicateFoundDialog>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import DuplicateFoundDialog from '@/components/crm/addresses/addAddress/DuplicateFoundDialog.vue'
import WarningDialog from '@/components/core/WarningDialog.vue'
import axios from 'axios'

export default {
  components: { DuplicateFoundDialog, WarningDialog },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      options: state => state.crmAddresses.addressOptions,
      duplicateNonPersons: state => state.crmAddresses.duplicates.nonPersons,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,
    street: '',
    housenumber: '',
    streetSuggestions: []
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidGeneralInfo', newValidity)
    },
    checkIfDuplicate() {
      //TODO: check for duplicate
      if (
        this.duplicateNonPersons == null &&
        this.address.name != '' &&
        this.address.address.zip != '' &&
        this.address.address.city != '' &&
        this.address.address.street != '' &&
        this.address.address.country != null
      ) {
        let duplicateCheckObject = {
          salutation: this.address.salutation,
          name: this.address.name,
          address: {
            zip: this.address.address.zip,
            city: this.address.address.city,
            street: this.address.address.street,
            country: this.address.address.country
          }
        }

        console.log(duplicateCheckObject)
        this.$store
          .dispatch('checkDuplicateNonPersons', duplicateCheckObject)
          .then(() => {
            if (this.duplicateNonPersons != []) {
              this.$refs.warning.open(
                'Folgende Adresse existiert bereits: \n' +
                  `${this.duplicateNonPersons[0].displayName}.`,
                [{ action: 'compare', text: 'Vergleichen und Fortfahren' }]
              )
            }
          })
      }
    },
    loadCompanyDefaultOptions() {
      if (this.address.isCompany) {
        this.$store.dispatch('loadCompanyOptions')
      }
    },
    loadSupplierDefaultOptions() {
      if (this.address.isSupplier) {
        this.$store.dispatch('loadSupplierOptions')
      }
    },
    loadHealthInsuranceCompanyOptions() {
      if (this.address.isHealthInsuranceCompany) {
        this.$store.dispatch('loadHealthInsuranceCompanyOptions')
      }
    },
    warningActionPerformed() {
      let comparedAddress = {
        name: this.address.name,

        zip: this.address.address.zip,
        city: this.address.address.city,
        street: this.address.address.street
      }
      this.$refs.duplicate.open(comparedAddress, this.duplicateNonPersons[0])
    },
    autofillCity() {
      if (
        this.address.address.country != null &&
        (this.address.address.zip != null || this.address.address.zip != '')
      ) {
        axios
          .post('v1/crm/addresses/new/city', {
            plz: this.address.address.zip,
            country: this.address.address.country
          })
          .then(res => {
            this.address.address.city = res.data
          })
      }
    },
    updateModel() {
      this.address.address.street = this.street + ' ' + this.housenumber
      console.log(this.address.address.street)
    },
    updateNonPersonNavList() {
      this.$store.commit('updateNonPersonNavList')
    },

    updateStreetSuggestions() {
      axios
        .post('v1/crm/addresses/new/street', {
          plz: this.address.address.zip,
          query: this.street
        })
        .then(res => {
          this.streetSuggestions = res.data.streets
        })
    }
  }
}
</script>

<style></style>
