<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-row>
      <v-col cols="12" lg="4" xl="4" md="4">
        <v-text-field
          label="HV-Nr."
          outlined
          append-icon="mdi-numeric"
          :rules="[rules.required, rules.sixDigitNumber]"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="8" xl="8" md="8">
        <v-autocomplete
          :items="doctorOptions.subjects"
          item-text="description"
          item-value="id"
          label="Fachrichtung"
          outlined
          prepend-inner-icon="mdi-stethoscope"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      doctorOptions: state => state.crmAddresses.doctorOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      sixDigitNumber: value =>
        /^\d{6}$/.test(value) || 'Bitte geben Sie eine 6-stellige Zahl ein!'
    },
    formValidity: false
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidDoctor', newValidity)
    }
  }
}
</script>

<style></style>
