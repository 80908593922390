<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-autocomplete
          outlined
          label="* Krankenkasse"
          prepend-inner-icon="mdi-hospital-building"
          :items="patientOptions.healthInsuranceCompanies"
          item-text="displayName"
          :item-value="item => item"
          clearable
          v-model="address.patient.healthInsuranceCompany"
          :rules="[rules.required]"
          auto-select-first
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="2" lg="2" xl="2">
        <v-checkbox
          :label="'Diabetes'"
          v-model="address.patient.diseases.diabetes"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-checkbox
          :label="'Angiopathie'"
          v-model="address.patient.diseases.angiopathe"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-checkbox
          :label="'Neuropathie'"
          v-model="address.patient.diseases.neuropathie"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12" xl="12">
        <v-textarea
          clearable
          rows="2"
          label="Allergien"
          outlined
          append-icon="mdi-clipboard-plus-outline"
          v-model="address.patient.allergies"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-autocomplete
          outlined
          label="* Versichertengruppe"
          prepend-inner-icon="mdi-tag-text"
          :items="this.patientOptions.insuredGroups"
          :item-text="item => item.description"
          item-value="id"
          clearable
          v-model="address.patient.insuredGroup"
          :rules="[rules.required]"
          @change="checkIfRelative()"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-checkbox
          :label="'Gebührenfrei'"
          v-model="address.patient.isFeeReady"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-text-field
          outlined
          append-icon="mdi-calendar-blank"
          label="Regojahr"
          :rules="[rules.onlyPositive]"
          v-model="address.patient.regojahr"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="* SVNR"
          append-icon="mdi-card-account-details"
          :rules="[rules.required]"
          :error-messages="errorMessages"
          @change="checkSVNR()"
          v-model="address.patient.svnr"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="* SVNR Dat."
          :rules="[rules.onlyPositive, rules.required]"
          :error-messages="errorMessages"
          @change="checkSVNR()"
          v-model="address.patient.svnrDat"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-autocomplete
          outlined
          prepend-inner-icon="mdi-doctor"
          label="* Arzt"
          :items="patientOptions.familyDoctors"
          item-text="displayName"
          :item-value="item => item.key"
          :rules="[rules.required]"
          clearable
          v-model="address.patient.familyDoctor"
          @change="print()"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <!-- TODO: add v-model -->
        <!--v-model="address.patient.familyDoctor.hvnr"-->
        <v-text-field outlined readonly label="HV-Nr."></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'

import axios from 'axios'

export default {
  components: {},
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validPatient: state => state.crmAddresses.validPatient,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,

    errorMessages: []
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidPatient', newValidity)
    },
    checkIfRelative() {
      let insuredGroup = this.patientOptions.insuredGroups.filter(
        elem => elem.id == this.address.patient.insuredGroup
      )[0]

      if (
        insuredGroup != undefined &&
        insuredGroup.description.includes('Angehörige')
      ) {
        this.address.isRelative = true
      } else {
        this.address.isRelative = false
      }
    },
    async checkSVNR() {
      let svnr = this.address.patient.svnr + '' + this.address.patient.svnrDat
      await axios
        .post('v1/crm/addresses/new/checksv', null, {
          params: {
            svnr: svnr
          }
        })
        .then(res => {
          if (res.data) {
            this.errorMessages = []
            return true
          } else {
            this.errorMessages.push('SVNR ungültig!')
            return false
          }
        })
        .catch(() => {
          this.errorMessages.push('SVNR ungültig!')
          return false
        })
    },
    print() {
      console.log(this.address.patient.familyDoctor)
    }
  }
}
</script>

<style></style>
