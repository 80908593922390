<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            v-model="address.isConsumer"
            :label="
              addressTypes.find(type => type.fldName === 'kd_konsument')
                .fldDisplayName
            "
            @change="updatePersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            v-model="address.isAdultRepresentation"
            :label="
              addressTypes.find(type => type.fldName === 'kd_sachwalter')
                .fldDisplayName
            "
            @change="updatePersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            v-model="address.isPatient"
            :label="
              addressTypes.find(type => type.fldName === 'kd_patient')
                .fldDisplayName
            "
            @change="loadPatientDefaults(), updatePersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_arzt')
                .fldDisplayName
            "
            v-model="address.isDoctor"
            @change="loadDoctorDefaults(), updatePersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_verkaeufer')
                .fldDisplayName
            "
            v-model="address.isEmployee"
            @change="loadEmployeeDefaults(), updatePersonNavList()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-checkbox
            :label="
              addressTypes.find(type => type.fldName === 'kd_interessent')
                .fldDisplayName
            "
            v-model="address.isInterested"
            @change="updatePersonNavList()"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="2" xl="2" lg="2">
          <v-text-field
            v-model="address.salutation"
            outlined
            label="* Anrede"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="8" md="2" xl="2" lg="2">
          <v-autocomplete
            outlined
            label="Titel"
            v-model="address.title"
            :items="titleSuggestions"
            @keyup="autofillTitle()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-text-field
            outlined
            append-icon="mdi-account"
            label="* Nachname"
            @change="checkIfDuplicate()"
            v-model="address.lastname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-text-field
            outlined
            append-icon="mdi-account"
            label="* Vorname"
            v-model="address.firstname"
            @change="checkIfDuplicate(), autofillGender()"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-autocomplete
            outlined
            label="* Geschlecht"
            :items="options.genders"
            item-text="bezeichnung"
            item-value="key"
            clearable
            @change="updateSalutation()"
            v-model="address.gender"
            :rules="[rules.required]"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ attrs }">
              <v-text-field
                v-model="dateFormatted"
                outlined
                label="* Geburtsdatum"
                append-icon="mdi-calendar"
                :rules="[rules.required]"
                v-bind="attrs"
                @click:append="menu = true"
                @blur="date = parseDate(dateFormatted)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title color="primary" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" xl="4" lg="4">
          <v-autocomplete
            append-icon="mdi-earth"
            outlined
            label="* Land"
            :rules="[rules.required]"
            :items="this.options.countries"
            item-text="description"
            item-value="id"
            clearable
            @change="checkIfDuplicate(), autofillCity()"
            v-model="address.address.country"
            auto-select-first
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2" xl="2" lg="2">
          <v-text-field
            outlined
            label="* PLZ"
            v-model="address.address.zip"
            :rules="[rules.onlyPositive, rules.required]"
            @change="checkIfDuplicate(), autofillCity()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" xl="5" lg="5">
          <v-text-field
            outlined
            label="* Ort"
            v-model="address.address.city"
            :rules="[rules.required]"
            @change="checkIfDuplicate()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-autocomplete
            outlined
            label="* Straße"
            append-icon="mdi-home"
            :rules="[rules.required]"
            :items="this.streetSuggestions"
            v-model="street"
            @blur="checkIfDuplicate()"
            @keyup="updateStreetSuggestions(), updateModel()"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" xl="2" lg="2">
          <v-text-field
            outlined
            label="* Hausnummer"
            :rules="[rules.required]"
            v-model="housenumber"
            @keyup="updateModel()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            label="* Tel. 1"
            append-icon="mdi-phone"
            v-model="address.tel"
            :rules="[rules.onlyPositive, rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            append-icon="mdi-pencil"
            label="Anmerkung"
            v-model="address.telNote"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            label="Tel. Mobil"
            append-icon="mdi-phone"
            v-model="address.mobile"
            :rules="[rules.onlyPositive]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" xl="3" lg="3">
          <v-text-field
            outlined
            append-icon="mdi-pencil"
            label="Anmerkung"
            v-model="address.mobileNote"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            outlined
            append-icon="mdi-email"
            label="E-Mail"
            v-model="address.email"
            placeholder="max.mustermann@gmail.com"
            :rules="[rules.emailFormat]"
            type="email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-autocomplete
            outlined
            label="Heim"
            :items="this.options.residences"
            item-text="displayName"
            item-value="key"
            clearable
            v-model="address.residence"
            auto-select-first
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-autocomplete
            outlined
            label="Kundengruppe"
            :items="this.options.customerGroups"
            item-text="name"
            item-value="key"
            clearable
            v-model="address.customerGroup"
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-autocomplete
            outlined
            label="Erwachsenenvertretung"
            :items="this.options.adultRepresentations"
            item-text="displayName"
            item-value="key"
            v-show="!address.isAdultRepresentation"
            clearable
            v-model="address.adultRepresentation"
            auto-select-first
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <WarningDialog
      ref="warning"
      @warningActionPerformed="warningActionPerformed"
    />
    <DuplicateFoundDialog ref="duplicate"></DuplicateFoundDialog>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import DuplicateFoundDialog from '@/components/crm/addresses/addAddress/DuplicateFoundDialog.vue'
import WarningDialog from '@/components/core/WarningDialog.vue'
import axios from 'axios'

export default {
  components: { DuplicateFoundDialog, WarningDialog },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      options: state => state.crmAddresses.addressOptions,
      duplicatePersons: state => state.crmAddresses.duplicates.persons,
      addressTypes: state => state.crmAddresses.addressTypes
    }),
    computedDateFormatted() {
      return this.formatDate(this.date)
    }
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value => /^[0-9]*$/.test(value) || 'Nur Zahlen sind erlaubt!'
    },
    date: null,
    dateFormatted: null,
    menu: false,
    formValidity: false,
    streetSuggestions: [],
    titleSuggestions: [],
    street: '',
    housenumber: ''
  }),
  methods: {
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    loadPatientDefaults() {
      if (this.address.isPatient) {
        //Get Select Options
        this.$store.dispatch('loadPatientOptions').then(() => {
          //get Doctors
          this.$store.dispatch('loadPatientFamilyDoctors', '')
        })
      }
    },
    loadEmployeeDefaults() {
      if (this.address.isEmployee) {
        this.$store.dispatch('loadEmployeeOptions')
      }
    },
    loadDoctorDefaults() {
      if (this.address.isDoctor) {
        this.$store.dispatch('loadDoctorOptions')
      }
    },
    checkIfDuplicate() {
      //TODO: check for duplicate
      if (
        this.duplicatePersons == null &&
        this.address.lastname != '' &&
        this.address.firstname != '' &&
        (this.address.dateOfBirth != '' ||
          (this.address.address.zip != '' &&
            this.address.address.city != '' &&
            this.address.address.street != ''))
      ) {
        // TODO: NO 500 when no duplicate found!
        // TODO: also return DOB of found person
        let duplicateCheckObject = {
          salutation: this.address.salutation,
          title: this.address.title,
          firstname: this.address.firstname,
          lastname: this.address.lastname,
          dateOfBirth: this.address.dateOfBirth,

          address: {
            zip: this.address.address.zip,
            city: this.address.address.city,
            street: this.address.address.street,
            country: this.address.address.country
          }
        }
        this.$store
          .dispatch('checkDuplicatePersons', duplicateCheckObject)
          .then(() => {
            //NO ERROR -> duplicate found
            if (this.duplicatePersons != []) {
              this.$refs.warning.open(
                'Folgende Adresse existiert bereits: \n' +
                  `${this.duplicatePersons[0].displayName}.`,
                [{ action: 'compare', text: 'Vergleichen und Fortfahren' }]
              )
            }
          })
      }
    },
    autofillCity() {
      if (
        this.address.address.country != null &&
        (this.address.address.zip != null || this.address.address.zip != '')
      ) {
        console.log(this.address.address)
        axios
          .post('v1/crm/addresses/new/city', {
            plz: this.address.address.zip,
            country: this.address.address.country
          })
          .then(res => {
            this.address.address.city = res.data
          })
      }
    },
    autofillGender() {
      axios
        .get('v1/crm/addresses/new/gender', {
          params: { name: this.address.firstname }
        })
        .then(res => {
          this.address.gender = res.data.key
          this.updateSalutation()
        })
    },
    autofillTitle() {
      axios
        .get('v1/crm/addresses/new/title', {
          params: { q: this.address.title }
        })
        .then(res => {
          console.log(res)
          this.titleSuggestions = res.data
        })
    },
    updateStreetSuggestions() {
      axios
        .post('v1/crm/addresses/new/street', {
          plz: this.address.address.zip,
          query: this.street
        })
        .then(res => {
          console.log(res)
          this.streetSuggestions = res.data.streets
        })
    },
    updateModel() {
      this.address.address.street = this.street + ' ' + this.housenumber
      console.log(this.address.address.street)
    },
    warningActionPerformed() {
      let comparedAddress = {
        name: this.address.lastname + ' ' + this.address.firstname,

        zip: this.address.address.zip,
        city: this.address.address.city,
        street: this.address.address.street
      }
      this.$refs.duplicate.open(comparedAddress, this.duplicatePersons[0])
    },
    updateSalutation() {
      //Find the correct gender from options and extract the correct anrede

      if (this.address.gender == null) {
        this.address.salutation = ''
        return
      }
      this.address.salutation = this.options.genders.find(
        item => item.key.lfdnr == this.address.gender.lfdnr
      ).anrede
    },
    updateSVNRDat() {
      var date = new Date(this.address.dateOfBirth)
      let month = date.getMonth() + 1 //needed because get month returns index
      var svnrDat =
        date
          .getDate()
          .toString()
          .padStart(5, '0')
          .slice(-2) +
        month
          .toString()
          .padStart(5, '0')
          .slice(-2) +
        date
          .getFullYear()
          .toString()
          .slice(-2)

      this.address.patient.svnrDat = svnrDat
    },
    updateValidity(newValidity) {
      this.$store.commit('updateValidAddress', newValidity)
    },
    updatePersonNavList() {
      this.$store.commit('updatePersonNavList')
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
      this.address.dateOfBirth = this.date
    }
  }
}
</script>

<style></style>
