<template>
  <!-- TODO: if once dismissed do not open again -->
  <v-dialog
    scrollable
    max-width="500px"
    persistent
    v-model="duplicateFoundDialog"
  >
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
          <v-icon @click="cancel">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Duplikatsvergleich</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-5 text-h5">
          <v-col cols="7">
            <p>Neue Adresse</p>
          </v-col>
          <v-col cols="5" class="text-right">
            <p>Vorhanden</p>
          </v-col>
        </v-row>
        <div v-for="(val, key) in address" :key="key" fluid>
          <v-row v-if="address[key] == duplicate[key]">
            <v-col cols="5">
              <p>
                <b class="button">{{ key }}:</b> {{ address[key] }}
              </p>
            </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="5" class="text-right">
              <p>
                <b class="button">{{ key }}:</b> {{ duplicate[key] }}
              </p>
            </v-col>
          </v-row>
          <v-row class="font-weight-black primary--text align-center" v-else>
            <v-col cols="5">
              <p>
                <b class="button">{{ key }}:</b>
                {{ address[key] != '' ? address[key] : '-' }}
              </p>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-chip color="primary" class="mt-n3">
                <v-icon>mdi-not-equal-variant</v-icon>
              </v-chip>
            </v-col>
            <v-col cols="5" class="text-right">
              <p>
                <b class="button">{{ key }}:</b>
                {{ duplicate[key] != '' ? duplicate[key] : '-' }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="cancel">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    duplicateFoundDialog: false,
    address: null,
    duplicate: null
  }),
  methods: {
    open(newAddress, duplicate) {
      console.log(newAddress)
      this.address = newAddress
      this.duplicate = duplicate
      this.duplicateFoundDialog = true
    },
    cancel() {
      this.duplicateFoundDialog = false
    }
  }
}
</script>

<style>
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
