<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-row>
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-text-field
          outlined
          label="Vertragsnummer"
          append-icon="mdi-numeric"
          v-model="address.company.contractNumber"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-text-field
          outlined
          label="UID"
          append-icon="mdi-identifier"
          v-model="address.company.uid"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5">
        <v-autocomplete
          outlined
          label="Kundengruppe"
          :items="this.options.customerGroups"
          item-text="name"
          item-value="key"
          v-model="address.company.customerGroup"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-autocomplete
          outlined
          label="Verband"
          prepend-inner-icon="mdi-account-group"
          :items="this.companyOptions.associations"
          v-model="address.company.association"
          item-text="name"
          item-value="key"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-autocomplete
          outlined
          :items="this.companyOptions.industrialSectors"
          item-text="description"
          item-value="id"
          label="Branche"
          v-model="address.company.industrialSectors"
          prepend-inner-icon="mdi-account-hard-hat"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>

    <h2 class="overline font-weight-black mt-3">Lieferung und Bezahlung</h2>
    <v-divider />
    <v-row class="mt-3">
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-autocomplete
          outlined
          :items="this.companyOptions.shippingMethods"
          item-text="description"
          item-value="id"
          v-model="address.company.deliveryAndPayment.shippingMethod"
          prepend-inner-icon="mdi-truck"
          label="Versandart"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-autocomplete
          outlined
          :items="this.companyOptions.shippingConditions"
          item-text="description"
          item-value="id"
          v-model="address.company.deliveryAndPayment.shippingCondition"
          prepend-inner-icon="mdi-truck-alert"
          label="Lieferbedingung"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-autocomplete
          outlined
          :items="this.companyOptions.paymentConditions"
          item-text="description"
          item-value="id"
          prepend-inner-icon="mdi-cash"
          v-model="address.company.deliveryAndPayment.paymentCondition"
          label="Zahlungsbedingung"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-autocomplete
          outlined
          :items="this.companyOptions.salesPersons"
          item-text="displayName"
          item-value="key"
          label="Verkäufer"
          v-model="address.company.deliveryAndPayment.salesPerson"
          prepend-inner-icon="mdi-account"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          suffix="Tage"
          :rules="[rules.onlyNumbers, rules.onlyPositive]"
          outlined
          v-model="
            address.company.deliveryAndPayment.collectiveDeliveryNoteInterval
          "
          label="Sammelliefers. Int."
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          suffix="Tage"
          :rules="[rules.onlyNumbers, rules.onlyPositive]"
          outlined
          v-model="address.company.deliveryAndPayment.collectiveInvoiceInterval"
          label="Sammelr. Int."
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-autocomplete
          outlined
          label="Lager"
          :items="this.companyOptions.warehouses"
          item-text="description"
          item-value="id"
          v-model="address.company.deliveryAndPayment.warehouse"
          prepend-inner-icon="mdi-warehouse"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="5" xl="5" md="5">
        <v-autocomplete
          outlined
          label="Rabattart"
          :items="this.companyOptions.discountTypes"
          item-text="description"
          item-value="id"
          v-model="address.company.deliveryAndPayment.discountType"
          prepend-inner-icon="mdi-file-percent"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" xl="3" md="3">
        <v-text-field
          outlined
          suffix="%"
          label="Rabattausmaß"
          v-model="address.company.deliveryAndPayment.discount"
          :rules="[rules.onlyNumbers, rules.onlyPositive]"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="4" xl="4" md="4">
        <v-checkbox
          label="Verband ist Rechnungsadresse"
          v-model="
            address.company.deliveryAndPayment.associationIsInvoiceAddress
          "
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" xl="8" md="8">
        <v-autocomplete
          outlined
          :items="this.companyOptions.fibuGroups"
          item-text="description"
          item-value="id"
          label="Fibugruppe"
          v-model="address.company.deliveryAndPayment.fibuGroup"
          prepend-inner-icon="mdi-account-group"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      companyOptions: state => state.crmAddresses.companyOptions,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value =>
        value == '' ||
        value == null ||
        /^-?\d*\.?\d*$/.test(value) ||
        'Nur Zahlen sind erlaubt!'
    },
    formValidity: true,
    testValue: ''
  }),
  methods: {
    updateValidity(newValidity) {
      console.log(newValidity + '\n')
      this.$store.commit('updateValidCompany', newValidity)
    }
  }
}
</script>

<style></style>
