<template>
  <v-expansion-panels
    class="mt-n3"
    accordion
    multiple
    v-model="panelsOpenedByDefault"
  >
    <!-- Addressinformation -->

    <v-container ref="allgemein" class="ma-0 pa-0">
      <v-expansion-panel>
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
          >Allgemeine Informationen</v-expansion-panel-header
        >
        <v-expansion-panel-content class="py-5">
          <GeneralinformationForm></GeneralinformationForm>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>

    <v-container ref="kunde" class="ma-0 pa-0">
      <v-expansion-panel v-show="address.isCompany">
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
          >{{
            addressTypes.find(type => type.fldName === 'kd_kunde')
              .fldDisplayName
          }}</v-expansion-panel-header
        >
        <v-expansion-panel-content class="py-5">
          <CompanyinformationForm />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>

    <v-container ref="lieferant" class="ma-0 pa-0">
      <v-expansion-panel v-show="address.isSupplier">
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
          >{{
            addressTypes.find(type => type.fldName === 'kd_lieferant')
              .fldDisplayName
          }}</v-expansion-panel-header
        >
        <v-expansion-panel-content class="py-5">
          <SupplierinformationForm />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'
import GeneralinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/GeneralinformationForm.vue'
import CompanyinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/CompanyinformationForm.vue'
import SupplierinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/SupplierinformationForm.vue'

export default {
  components: {
    GeneralinformationForm,
    CompanyinformationForm,
    SupplierinformationForm
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    panelsOpenedByDefault: [0, 1, 2, 3, 4]
  })
}
</script>

<style></style>
