<template>
  <v-expansion-panels
    class="mt-n3"
    accordion
    multiple
    v-model="panelsOpenedByDefault"
  >
    <!-- Addressinformation -->
    <div ref="allgemein"></div>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="primary--text text-h5 font-weight-black pl-9 pt-6"
        >Addressinformation</v-expansion-panel-header
      >
      <v-expansion-panel-content class="py-5">
        <AddressinformationForm />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- Patient -->
    <v-container ref="patient" class="ma-0 pa-0">
      <v-expansion-panel v-show="address.isPatient">
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
        >
          {{
            addressTypes.find(type => type.fldName === 'kd_patient')
              .fldDisplayName
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-5">
          <PatientinformationForm />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>

    <!-- MainInsuranceData-->
    <v-expansion-panel v-show="address.isRelative && address.isPatient">
      <v-expansion-panel-header
        class="primary--text text-h5 font-weight-black pl-9 pt-6"
      >
        Hauptversichertendaten
      </v-expansion-panel-header>
      <v-expansion-panel-content class="py-5">
        <MainInsuranceDataForm />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- Mitarbeiter -->
    <v-container ref="verkaeufer" class="ma-0 pa-0">
      <v-expansion-panel v-show="address.isEmployee">
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
        >
          {{
            addressTypes.find(type => type.fldName === 'kd_verkaeufer')
              .fldDisplayName
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-5">
          <EmployeeinformationForm />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>

    <!-- Arzt -->
    <v-container ref="arzt" class="ma-0 pa-0">
      <v-expansion-panel v-show="address.isDoctor">
        <v-expansion-panel-header
          class="primary--text text-h5 font-weight-black pl-9 pt-6"
        >
          {{
            addressTypes.find(type => type.fldName === 'kd_arzt').fldDisplayName
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-5">
          <DoctorinformationForm />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
  </v-expansion-panels>
</template>

<script>
import { mapState } from 'vuex'
import AddressinformationForm from '@/components/crm/addresses/addAddress/addPerson/AddressinformationForm.vue'
import PatientinformationForm from '@/components/crm/addresses/addAddress/addPerson/PatientinformationForm.vue'
import MainInsuranceDataForm from '@/components/crm/addresses/addAddress/addPerson/MainInsuranceDataForm.vue'
import EmployeeinformationForm from '@/components/crm/addresses/addAddress/addPerson/EmployeeinformationForm.vue'
import DoctorinformationForm from '@/components/crm/addresses/addAddress/addPerson/DoctorinformationForm.vue'

export default {
  components: {
    AddressinformationForm,
    PatientinformationForm,
    MainInsuranceDataForm,
    EmployeeinformationForm,
    DoctorinformationForm
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    panelsOpenedByDefault: [0, 1, 2, 3, 4]
  }),
  methods: {
    loadAddressTypes() {
      this.$store.dispatch('loadAddressTypes').then(() => {
        this.$store.commit('updatePersonNavList')
        this.$store.commit('updateNonPersonNavList')
      })
    }
  },
  mounted() {
    this.loadAddressTypes()
  }
}
</script>

<style></style>
