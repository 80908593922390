<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <!-- TODO: add v-models--><!-- TODO: expand address models-->
    <v-row>
      <v-col cols="12" lg="8" xl="8" md="8">
        <v-autocomplete
          outlined
          label="Krankenkasse"
          prepend-inner-icon="mdi-hospital-building"
          :items="employeeOptions.healthInsuranceCompanies"
          item-text="displayName"
          :item-value="item => item"
          v-model="address.employee.healthInsuranceCompany"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="SVNR"
          :error-messages="errorMessages"
          @change="checkSVNR()"
          append-icon="mdi-card-account-details"
          v-model="address.employee.svnr"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="SVNR Dat."
          v-model="address.employee.svnrDat"
          :error-messages="errorMessages"
          @change="checkSVNR()"
          :rules="[rules.onlyPositive]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" md="4" xl="4">
        <v-checkbox
          label="Kassenmitarbeiter"
          v-model="address.employee.isCashier"
          prepend-icon="mdi-cash-register"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="4" md="4" xl="4">
        <v-checkbox
          label="BDE-Mitarbeiter"
          v-model="address.employee.isBdeEmployee"
          prepend-icon="mdi-database-edit"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" lg="4" md="4" xl="4">
        <v-checkbox
          label="Externer Mitarbeiter"
          v-model="address.employee.isExternalEmployee"
          prepend-icon="mdi-account-arrow-left"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-text-field
          outlined
          label="Personalnummer"
          v-model="address.employee.staffId"
          append-icon="mdi-numeric"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6">
        <v-text-field
          outlined
          v-model="address.employee.idNumber"
          label="Ausweisnummer"
          append-icon="mdi-id-card"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" xl="4" lg="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date_entry"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ attrs }">
            <v-text-field
              v-model="dateFormatted_entry"
              outlined
              label="Eintrittsdatum"
              append-icon="mdi-calendar-import"
              v-bind="attrs"
              @click:append="menu = true"
              @blur="date_entry = parseDate(dateFormatted_entry)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_entry"
            no-title
            color="primary"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date_entry)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="4" xl="4" lg="4">
        <v-menu
          ref="menu_exit"
          v-model="menu_exit"
          :close-on-content-click="false"
          :return-value.sync="date_exit"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ attrs }">
            <v-text-field
              v-model="dateFormatted_exit"
              outlined
              label="Austrittsdatum"
              append-icon="mdi-calendar-export"
              v-bind="attrs"
              @click:append="menu_exit = true"
              @blur="date_exit = parseDate(dateFormatted_exit)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_exit"
            no-title
            color="primary"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu_exit = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu_exit.save(date_exit)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="4" xl="4" md="4">
        <v-autocomplete
          prepend-inner-icon="mdi-account"
          label="Loginname"
          v-model="address.employee.userId"
          outlined
          clearable
          :items="employeeOptions.activeUsers"
          item-text="username"
          :item-value="item => item.userId"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          outlined
          clearable
          prepend-inner-icon="mdi-warehouse"
          label="Lager"
          :items="employeeOptions.warehouses"
          item-text="description"
          v-model="address.employee.warehouse"
          :item-value="item => item"
          auto-select-first
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      employeeOptions: state => state.crmAddresses.employeeOptions,
      options: state => state.crmAddresses.addressOptions,
      validSVNRRule() {
        return async value => {
          // Perform SVNR validation asynchronously
          const valid = await this.checkSVNR(value)
          alert(valid)
          if (!valid) {
            return false
          }
          return true
        }
      }
    }),
    computedDateFormatted() {
      return this.formatDate(this.date_entry)
    }
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,
    date_entry: null,
    dateFormatted_entry: null,
    menu: false,
    menu_exit: false,
    date_exit: false,
    dateFormatted_exit: null,
    errorMessages: [],
    testDate: '',
    testDate2: ''
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidEmployee', newValidity)
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      alert('here')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null
      alert('there')
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    async checkSVNR() {
      let svnr = this.address.employee.svnr + '' + this.address.employee.svnrDat
      await axios
        .post('v1/crm/addresses/new/checksv', null, {
          params: {
            svnr: svnr
          }
        })
        .then(res => {
          if (res.data) {
            this.errorMessages = []
            return true
          } else {
            this.errorMessages.push('SVNR ungültig!')
            return false
          }
        })
        .catch(() => {
          this.errorMessages.push('SVNR ungültig!')
          return false
        })
    }
  },
  watch: {
    date_entry() {
      this.dateFormatted_entry = this.formatDate(this.date_entry)
      this.address.employee.entryDate = this.date_entry
    },
    date_exit() {
      this.dateFormatted_exit = this.formatDate(this.date_exit)
      this.address.employee.exitDate = this.date_exit
    }
  }
}
</script>

<style></style>
