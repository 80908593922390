<template>
  <v-form v-model="formValidity" @input="updateValidity(formValidity)">
    <v-row>
      <v-col cols="4" md="4" lg="4" xl="4">
        <v-autocomplete
          outlined
          label="* Geschlecht"
          :rules="[rules.required]"
          v-model="address.patient.mainInsuranceData.gender"
          :items="options.genders"
          item-text="bezeichnung"
          item-value="key"
          clearable
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" lg="4" xl="4">
        <v-text-field
          outlined
          label="* Nachname"
          :rules="[rules.required]"
          v-model="address.patient.mainInsuranceData.lastname"
        ></v-text-field>
      </v-col>
      <v-col cols="4" md="4" lg="4" xl="4">
        <v-text-field
          outlined
          label="* Vorname"
          :rules="[rules.required]"
          v-model="address.patient.mainInsuranceData.firstname"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-autocomplete
          outlined
          label="* Krankenkasse"
          :items="this.patientOptions.healthInsuranceCompanies"
          item-text="displayName"
          :item-value="item => item"
          clearable
          v-model="address.patient.mainInsuranceData.healthInsuranceCompany"
          :rules="[rules.required]"
          auto-select-first
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="* SVNR"
          :rules="[rules.required]"
          :error-messages="errorMessages"
          @change="checkSVNR()"
          v-model="address.patient.mainInsuranceData.svnr"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-text-field
          outlined
          label="* SVNR Dat."
          :error-messages="errorMessages"
          @change="checkSVNR()"
          :rules="[rules.onlyPositive, rules.required]"
          v-model="address.patient.mainInsuranceData.svnrDat"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  components: {},
  computed: {
    ...mapState({
      options: state => state.crmAddresses.addressOptions,
      patientOptions: state => state.crmAddresses.patientOptions,
      address: state => state.crmAddresses.newPerson
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value =>
        value >= 0 ||
        value == null ||
        value == '' ||
        'Wert kann nicht negativ sein!',
      emailFormat: v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,

    errorMessages: []
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidRelative', newValidity)
    },
    async checkSVNR() {
      let svnr =
        this.address.patient.mainInsuranceData.svnr +
        '' +
        this.address.patient.mainInsuranceData.svnrDat
      await axios
        .post('v1/crm/addresses/new/checksv', null, {
          params: {
            svnr: svnr
          }
        })
        .then(res => {
          if (res.data) {
            this.errorMessages = []
            return true
          } else {
            this.errorMessages.push('SVNR ungültig!')
            return false
          }
        })
        .catch(() => {
          this.errorMessages.push('SVNR ungültig!')
          return false
        })
    }
  }
}
</script>

<style></style>
