<template>
  <div>
    <v-row>
      <v-col cols="10" lg="10" xl="10" md="10">
        <v-tabs v-model="dialogTab" grow>
          <v-tab key="person"> Person </v-tab>
          <v-tab key="nonPerson"> Nichtperson </v-tab>
        </v-tabs>

        <v-tabs-items v-model="dialogTab" class="pt-3">
          <v-tab-item key="Person">
            <AddPersonTab ref="personTab" />
          </v-tab-item>

          <v-tab-item key="Nichtperson">
            <AddNonPersonTab ref="nonPersonTab"
          /></v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="4" lg="4" xl="4" md="4">
        <v-container>
          <v-navigation-drawer
            class="pt-15"
            fixed
            permanent
            right
            style="z-index: 2; height: 100%; overflow-y:auto"
          >
            <template v-slot:prepend>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-chip
                    v-if="dialogTab == 0"
                    outlined
                    class="mb-3 mt-10 pa-4 text-h6 font-weight-bold primary--text"
                    ><v-icon class="mx-2 primary--text"
                      >mdi-account-plus</v-icon
                    >
                    Person</v-chip
                  >
                  <v-chip
                    outlined
                    v-if="dialogTab == 1"
                    class="my-3 mb-3 mt-10 pa-4 text-h6 font-weight-bold primary--text"
                    ><v-icon class="mx-2">mdi-office-building-plus</v-icon>
                    Nicht-Person</v-chip
                  >
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list v-if="dialogTab == 0" dense>
              <v-list-item
                v-for="item in navigationList.personNavList"
                :key="item.title"
              >
                <v-list-item-content v-if="item.show">
                  <v-list-item-title
                    @click="refMeTo('personTab', item.ref)"
                    class="primary--text hover-pointer"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="text--disabled">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list v-if="dialogTab == 1" dense>
              <v-list-item
                v-for="item in navigationList.nonPersonNavList"
                :key="item.title"
              >
                <v-list-item-content v-if="item.show">
                  <v-list-item-title
                    @click="refMeTo('nonPersonTab', item.ref)"
                    class="primary--text hover-pointer"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title class="text--disabled">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <template v-slot:append>
              <v-divider></v-divider>
              <div class="pa-6">
                <v-btn class="primary" @click="save" :disabled="!isValid()">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  class="error ml-5"
                  @click="
                    resetValuesToDefault(),
                      updatePersonNavList(),
                      updateNonPersonNavList()
                  "
                >
                  <v-icon>mdi-eraser-variant</v-icon>
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddPersonTab from '@/components/crm/addresses/addAddress/addPerson/AddPersonTab.vue'
import AddNonPersonTab from '@/components/crm/addresses/addAddress/addNonPerson/AddNonPersonTab.vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      navigationList: state => state.crmAddresses.navList,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      validPatient: state => state.crmAddresses.validPatient,
      validRelative: state => state.crmAddresses.validRelative,
      validEmployee: state => state.crmAddresses.validEmployee,
      validDoctor: state => state.crmAddresses.validDoctor,
      validGeneralInfo: state => state.crmAddresses.validGeneralInfo,
      validCompany: state => state.crmAddresses.validCompany,
      person: state => state.crmAddresses.newPerson,
      nonPerson: state => state.crmAddresses.newNonPerson,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  components: {
    AddPersonTab,
    AddNonPersonTab
  },
  data: () => ({
    dialogTab: null
  }),
  methods: {
    refMeTo(tab, ref) {
      const childComponent = this.$refs[tab]
      if (childComponent) {
        const childRef = childComponent.$refs[ref]

        if (childRef) {
          console.log(childRef)
          childRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    },
    isValidPerson() {
      return (
        this.validAddress &&
        ((this.person.isPatient && this.validPatient) ||
          !this.person.isPatient) &&
        ((this.person.isRelative && this.validRelative) ||
          !this.person.isRelative) &&
        ((this.person.isEmployee && this.validEmployee) ||
          !this.person.isEmployee) &&
        ((this.person.isDoctor && this.validDoctor) || !this.person.isDoctor) &&
        (this.person.isConsumer ||
          this.person.isAdultRepresentation ||
          this.person.isPatient ||
          this.person.isEmployee ||
          this.person.isDoctor)
      )
    },
    isValidNonPerson() {
      return (
        (this.nonPerson.isCompany ||
          this.nonPerson.isAdultRepresentation ||
          this.nonPerson.isResidence) &&
        this.validGeneralInfo &&
        ((this.nonPerson.isCompany && this.validCompany) ||
          !this.nonPerson.isCompany)
      )
    },
    isValid() {
      if (this.dialogTab == 0) {
        return this.isValidPerson()
      } else if (this.dialogTab == 1) {
        return this.isValidNonPerson()
      }
    },
    save() {
      if (this.dialogTab == 0) {
        //--> person
        this.savePerson()
      } else if (this.dialogTab == 1) {
        //--> non-person
        this.saveNonPerson()
      }
      this.resetValuesToDefault()
    },
    savePerson() {
      if (this.person.isPatient) {
        //map healthInsurance company to its key
        this.person.patient.healthInsuranceCompany = {
          lfdnr: this.person.patient.healthInsuranceCompany.key.lfdnr,
          firma: this.person.patient.healthInsuranceCompany.key.firma
        }
        if (this.person.isRelative) {
          this.person.patient.mainInsuranceData.healthInsuranceCompany = {
            lfdnr: this.person.patient.mainInsuranceData.healthInsuranceCompany
              .key.lfdnr,
            firma: this.person.patient.mainInsuranceData.healthInsuranceCompany
              .key.firma
          }
        }
        this.person.patient.svnr = parseInt(this.person.patient.svnr)
      }

      this.$store.dispatch('saveNewPerson', this.person).then(result => {
        this.resetValuesToDefault()
        this.$router.push({
          name: 'crmAddress',
          params: { lfdnr: result.key.lfdnr }
        })
      })
    },
    saveNonPerson() {
      this.$store.dispatch('saveNewNonPerson', this.nonPerson).then(res => {
        alert(res.data)
        this.$router.push({
          name: 'crmAddress',
          params: { lfdnr: res.data.lfdnr }
        })
        this.resetValuesToDefault()
      })
    },
    loadDefaults() {
      this.$store.dispatch('loadAddAddressOptions')
    },
    resetValuesToDefault() {
      this.$store.commit('resetNewPerson')
      this.$store.commit('resetNewNonPerson')
    },
    updatePersonNavList() {
      this.$store.commit('updatePersonNavList')
    },

    updateNonPersonNavList() {
      this.$store.commit('updateNonPersonNavList')
    }
  },
  mounted() {
    this.loadDefaults()
  }
}
</script>

<style>
.hover-pointer:hover {
  cursor: pointer;
}
</style>
